import React, { useState, memo } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { overrideTailwindClasses } from "tailwind-override"
// import { useSwipeable } from "react-swipeable"

import Headroom from "react-headroom"
import CartButton from "../../features/cart/CartButton"
import { useBreakpoint } from "../../utils/useTailwindBreakpoint"

import "./styles.css"
import logo from "../../images/logo.jpg"
import { Facebook } from "react-feather"

const nav = {
  Home: "/#home",
  About: "#about",
  Features: "#features",
  "Contact Us": "#contact",
}

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  // const [offsetX, setOffsetX] = useState(0)
  // const [swiping, setSwiping] = useState(false)
  // 2xl:text-xl 2xl:px-6 2xl:py-5
  const navItemDefault = "block p-4 lg:py-3 xl:text-lg  whitespace-nowrap"
  const navItemClass = "text-blue-dark ml-4 lg:ml-0"
  const navItemCTAClass =
    "bg-blue-50 text-blue-800 mx-4 lg:mr-0 rounded 2xl:px-8"

  const isMobile = !useBreakpoint("lg")
  const translateMenu = isMobile ? `${!menuOpen * 100}% ` : `0px`

  return (
    <Headroom
      pin={true}
      // disable={true}
      // pinStart={0}
      // upTolerance={0}
      // downTolerance={100}
      disableInlineStyles
    >
      <div className="relative bg-white centered-container">
        <div
          className={`flex items-center justify-between py-4  ${
            menuOpen && "menu-open"
          }`}
        >
          <Link to="#home" className="mr-auto">
            <img
              className="h-10 lg:h-12 2xl:h-16"
              src={logo}
              alt="Smart Energy Logo"
            />
          </Link>

          {/* Overlay */}
          <div
            className="fixed top-0 left-0 right-0 z-40 items-end h-screen opacity-0 bg-gray-900/60 menu__overlay lg:hidden"
            onClick={() => setMenuOpen(false)}
            role="none"
          ></div>

          <div className="flex gap-4">
            {/* Menu (slide on sm) */}
            <nav
              className={`fixed top-0 right-0 z-40 w-11/12 h-screen max-w-sm pt-4 bg-white lg:static lg:h-auto lg:max-w-none lg:w-auto lg:pt-0 my-menu ${
                isMobile && "duration-300"
              }`}
              style={{
                transform: `translateX(${translateMenu})`,
              }}
            >
              <ul className="flex flex-col lg:items-center lg:flex-row">
                {/* Nav items */}
                {Object.entries(nav).map(([title, to], i, arr) => (
                  <li key={title}>
                    <Link
                      to={to}
                      onClick={() => setMenuOpen(false)}
                      className={overrideTailwindClasses(
                        `${navItemDefault} ${
                          i === arr.length - 1 ? navItemCTAClass : navItemClass
                        }`
                      )}
                      activeClassName="font-semibold"
                      // Set this to match sub directories as well
                      partiallyActive={to !== "/"}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
                <li className="mt-3 lg:mt-0">
                  <a
                    href="https://www.facebook.com/LithiVoltLLC"
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center justify-center w-8 h-8 ml-4 text-white bg-blue-600 rounded-full"
                  >
                    <Facebook
                      className="mr-0.5"
                      fill="white"
                      stroke="transparent"
                    />
                  </a>
                </li>
              </ul>
            </nav>

            {/* <CartButton /> */}

            {/* Mobile Menu toggle */}
            <button
              className="z-40 block menu__toggle lg:hidden"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <span></span>
            </button>
          </div>
        </div>
      </div>
    </Headroom>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default memo(Header)
